blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre,
html,
body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
}

html {
  overscroll-x: contain;
  height: 100%
}
body {
  height: 100%;
}
#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

*,
*::before,
*::after {
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.default', currentColor);
}

.LeftHexagonsWrapper {
  margin-top: -50px;
  margin-left: -50px;
}

.RightHexagonsWrapper {
  margin-right: -50px;
  margin-top: -20px;
}

.RightHexagonsWrapperBottom {
  margin-right: -40px;
  margin-top: 50px;
  margin-left: -100px;
  width: 500px;
  transform: rotate(60deg);
  opacity: .6;
  position: absolute;
}

.headerSegment {
  height: calc(100vh - 150px);
  min-height: 500px;
  box-sizing: border-box;
}

.text-orange-custom {
  color: #F7891B;
}

.splitterBottom {
  width:100%;
  height:100px;
/*  position:absolute; */
  left:0px;
  background: linear-gradient(to left top, #2e3749 49%, #fff 50%);
}


.splitterTop {
  position: relative;
  width: 100%;
  height: 100px;
  margin-top: -100px;
}

.splitterTop::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  z-index: 2;
}

.splitterTop::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: transparent;
  clip-path: polygon(100% 0, 0 0, 0 100%);
  z-index: 0;
}


.articleSpacer {
  height: 4px;
  width: 80px;
  background-color: #3B8EF0;
  box-shadow: 0px 3px 15px 0px rgba(59,142,240,0.62);
  border-radius: 50px;
}

.blogSegment {
  position: relative;
  min-height: 600px;
  z-index: 10;
  background-color: #fff;
}

.article::before {
  content: url((./assets/Polygon.svg);
  left: -47px;
  margin-top: -8px;
  position: absolute;
}

.timelime {
  position: absolute;
  top: -400px;
  left: 300px;
}

.timeLineIntro {
  height: 400px;
  width: 2px;
  background-color: #CCCCCC;
  position: absolute;
  top: 345px;
  margin-left: -40px;
}
.timeLineIntroScrolled {
  height: 0px;
  width: 2px;
  background-color: #CCCCCC;
  position: absolute;
  top: 345px;
  margin-left: -40px;
}
.timeLineIntro::before {
 content: url(./assets/PolygonNobg.svg);
 top: -43px;
 left: -46px;
 position: absolute;
}
.timeLineBlog {
 height: 0px;
 width: 2px;
 background-color: #CCCCCC;
 position: absolute;
 top: -50px;
 margin-left: -40px;
}

.contactIntro::before {
  width: 2px;
  height: 270px;
  background-color: #9A9A9A;
  position: absolute;
  margin-left: -30px;
  content: "";
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timelineBlogCenter {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #ccc;
  top: 100px;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}
.timeLineBlogBefore {
  content: '';
  position: absolute;
  width: 0px;
  height: 2px;
  background-color: #ccc;
  top: 100px;
  left: calc(50% - 201px);
}
.timelineEnd {
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #ccc;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  overflow: hidden;
}

/* Container around content */
.container {
  padding: 10px 30px;
  position: relative;
  background-color: inherit;
  width: 50%;
}
.articleRight {
  left: 50%;
}

.articleLeft::before {
 content: url((./assets/Polygon.svg);
 top: 20px;
 right: -22px;
 position: absolute;
 z-index: 20;
}
.articleRight::before {
 content: url(./assets/Polygon.svg);
 top: 20px;
 left: -49px;
 position: absolute;
 z-index: 20;
}
.articleRight::after {
  left: -16px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 1024px) {

  .timeLineIntro {
    margin-left: 0px;
    left: 28px;
  }
  .timeLineBlog {
   height: 370px;
   margin-left: 0px;
   left: 28px;
  }

  .articleRight::before {
   display: none;
  }
  .articleLeft::before {
   display: none;
  }

  .timeline::before {
    display: none;
  }

/* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

/* Full-width containers */
  .container {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }

/* Make all right containers behave like the left ones */
  .articleRight {
    left: 0% !important;
  }
  .timeLineIntroScrolled {
    margin-left: 0px;
    left: 28px;
  }
}

#nav-icon2 {
  width: 60px;
  height: 45px;
  position: relative;
  float: right;
  margin: 30px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 50%;
  background: #d3531a;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 18px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 36px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}

.aboutSegment {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.aboutSegment .centerImage {
  border-radius: 1000px;
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 999;
}
.aboutSegment div:not(.centerImage):before {
  left: 50%;
  border-radius: 100px;
  content: "";
  position: absolute;
}

.centerImage {
  height: 150px;
  width: 150px;
  margin-top: -75px;
  margin-left: -75px;
  background-clip: padding-box;
  border: 0 !important;
  background-position: -28px -103px;
  background-size: 175%;
  box-shadow: 0 0 10px 2px rgba(255, 107, 0, 0.4), 0 0 22px 11px rgba(255, 203, 0, 0.13);
}

.innerCircle {
  height: 340px;
  width: 340px;
  border-radius: 1000px;
  border: 1px solid #777;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -170px;
  margin-left: -170px;
}

.outerCircle {
  height: 615px;
  width: 615px;
  border-radius: 1000px;
  border: 1px solid #777;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -308px;
  margin-left: -308px;
}

.rotation {
  height: 230px;
  width: 230px;
  margin-top: -115px;
  margin-left: -115px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1; /* to prevent overlay over bug */
}
.rotationbig {
  height: 430px;
  width: 430px;
  margin-top: -215px;
  margin-left: -215px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.iconText {
    bottom: -26px;
    text-align: center;
    margin-left: -50px;
    width: 100px;
    position: absolute;
    left: 50%;
}
.inner {
  height: 56px;
  width: 56px;
  background: #EBEBEB;
  margin-top: -28px;
  margin-left: -28px;
  border-radius: 1000px;
  cursor: pointer;
}
.inner.small {
  height: 46px;
  width: 46px;
  background: #EBEBEB;
  margin-top: -23px;
  margin-left: -23px;
  border-radius: 1000px;
  cursor: pointer;
}
.inner.big {
  height: 66px;
  width: 66px;
  background: #EBEBEB;
  margin-top: -33px;
  margin-left: -33px;
  border-radius: 1000px;
}
.inner img {
  width: 30px;
  margin-left: 8px;
  padding-top: 5px;
}
.inner i {
  font-size: 24px;
  margin-left: 16px;
  margin-top: 16px;
}
.small i {
  font-size: 24px;
  margin-left: 8px;
  margin-top: 10px;
}
.big i {
  font-size: 30px;
  margin-left: 17px;
  margin-top: 18px;
}

.rotation.pos1 {
  animation: circle 60s linear infinite;
}
.rotation.pos1 .inner {
  animation: inner-circle 60s linear infinite;
}

.rotation.pos2 {
  animation: circle2 60s linear infinite;
}
.rotation.pos2 .inner {
  animation: inner-circle2 60s linear infinite;
}

.rotation.pos3 {
  animation: circle3 60s linear infinite;
}
.rotation.pos3 .inner {
  animation: inner-circle3 60s linear infinite;
}

.rotationbig.pos4 {
  animation: circle4 60s linear infinite;
}
.rotationbig.pos4 .inner {
  animation: inner-circle4 60s linear infinite;
}

.rotationbig.pos5 {
  animation: circle5 60s linear infinite;
}
.rotationbig.pos5 .inner {
  animation: inner-circle5 60s linear infinite;
}

.rotationbig.pos6 {
  animation: circle6 60s linear infinite;
}
.rotationbig.pos6 .inner {
  animation: inner-circle6 60s linear infinite;
}

.rotationbig.pos7 {
  animation: circle7 60s linear infinite;
}
.rotationbig.pos7 .inner {
  animation: inner-circle7 60s linear infinite;
}

.rotationbig.pos8 {
  animation: circle8 60s linear infinite;
}
.rotationbig.pos8 .inner {
  animation: inner-circle8 60s linear infinite;
}

@keyframes circle {
    from { transform:rotate(0deg); }
    to { transform:rotate(360deg); }
}
@keyframes circle2 {
    from { transform:rotate(120deg); }
    to { transform:rotate(480deg); }
}
@keyframes circle3 {
    from { transform:rotate(240deg); }
    to { transform:rotate(600deg); }
}
@keyframes circle4 {
    from { transform:rotate(-72deg); }
    to { transform:rotate(-432deg); }
}
@keyframes circle5 {
    from { transform:rotate(-144deg); }
    to { transform:rotate(-504deg); }
}
@keyframes circle6 {
    from { transform:rotate(-216deg); }
    to { transform:rotate(-576deg); }
}
@keyframes circle7 {
    from { transform:rotate(-288deg); }
    to { transform:rotate(-648deg); }
}
@keyframes circle8 {
    from { transform:rotate(-360deg); }
    to { transform:rotate(-720deg); }
}

@keyframes inner-circle {
    from { transform:rotate(0deg); }
    to { transform:rotate(-360deg); }
}

@keyframes inner-circle2 {
    from { transform:rotate(-120deg); }
    to { transform:rotate(-480deg); }
}
@keyframes inner-circle3 {
    from { transform:rotate(-240deg); }
    to { transform:rotate(-600deg); }
}
@keyframes inner-circle4 {
    from { transform:rotate(72deg); }
    to { transform:rotate(432deg); }
}
@keyframes inner-circle5 {
    from { transform:rotate(144deg); }
    to { transform:rotate(504deg); }
}
@keyframes inner-circle6 {
    from { transform:rotate(216deg); }
    to { transform:rotate(576deg); }
}
@keyframes inner-circle7 {
    from { transform:rotate(288deg); }
    to { transform:rotate(648deg); }
}
@keyframes inner-circle8 {
    from { transform:rotate(360deg); }
    to { transform:rotate(720deg); }
}

.skills_img {
  top: 30px;
  height: 400px;
}

.aboutHexLeft {
  top: 0px;
  width: 400px;
  opacity: 70%;
}

.aboutHexRight {
  top: -50px;
  right: -100px;
  width: 400px;
  opacity: 70%;
}

.inner:hover .iconText {
  display: block;
  -webkit-animation: fadein 0.4s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadein 0.4s; /* Firefox < 16 */
    -ms-animation: fadein 0.4s; /* Internet Explorer */
     -o-animation: fadein 0.4s; /* Opera < 12.1 */
        animation: fadein 0.4s;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


.line {
  width: 100%;
  max-width: 800px;
  height: 8px;
  margin: 0 0 10px 0;
  position: relative;
  display: inline-block;
  background-color: rgba(255,255,255,1);
}
/* .displayArticle2, .displayArticle4 {
  left: 60%;
} */
.displayArticle3, .displayArticle {
  margin-left: -100px;
}
.blogBackgroundImg1 {
  position: absolute;
  left: 20%;
  opacity: .5;
  margin-top: 50px;
}
.blogBackgroundImg2 {
  position: absolute;
  right: 20%;
  opacity: .5;
  margin-top: 50px;
}
.introtext-1, .introtext-2, .introtext-3, .introtext-4, .timeLineIntro, .displayArticle2, .displayArticle3, .displayArticle4 {
  opacity: 0;
}

.displayArticle {
  opacity: 0;
}

.macTerminal {
  font-family: "Lucida Console", Monaco, monospace;
  width: 600px;
  height: 300px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
  margin: 0 auto;
}
.terminalButton {
  width: 13px;
  height: 13px;
}
.terminalHeader {
  padding-top: 5px;
  padding-bottom: 5px;
}
.terminalLine {
  font-family: "Lucida Console", Monaco, monospace;
  white-space: nowrap;
  overflow: hidden;
}

.line-1{
    width: 160px;
    /* border-right: 2px solid rgba(255,255,255,.75); */
}
.line-2 {
  width: 500px;
}
.line-3 {
  width: 400px;
}
.line-4 {
  width: 600px;
}
.line-5 {
  width: 600px;
}
.line-6 {
  width: 500px;
}
/* Animation */
.anim-typewriter{
  width:0;
}


.mouse {
  width: 30px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 60px;
  position: relative;
  opacity: 0.7;
}
.mouse::before {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 2s infinite;
  -webkit-animation: wheel 2s infinite;
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 25px;
  }
}
@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 25px;
  }
}




.menu-icon {
  -webkit-transform: scale(2);
          transform: scale(2);
}

.menu-icon {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.menu-icon .menu-icon__cheeckbox {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  z-index: 2;
  -webkit-touch-callout: none;
  position: absolute;
  opacity: 0;
}
.menu-icon div {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 22px;
  height: 12px;
}
.menu-icon span {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 1px;
  -webkit-transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
  transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}
.menu-icon span:first-of-type {
  top: 0;
}
.menu-icon span:last-of-type {
  bottom: 0;
}
.menu-icon.active span:first-of-type,
.menu-icon .menu-icon__cheeckbox:checked + div span:first-of-type {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 5px;
}
.menu-icon.active span:last-of-type,
.menu-icon .menu-icon__cheeckbox:checked + div span:last-of-type {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  bottom: 5px;
}
.menu-icon.active:hover span:first-of-type, .menu-icon.active:hover span:last-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:first-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
  width: 22px;
}
@media (min-width: 1024px) {
  .menu-icon:hover span:first-of-type {
    width: 26px;
  }
  .menu-icon:hover span:last-of-type {
    width: 12px;
  }
}

.BackgroundImgWrapper {
  position: relative;
}
.BackgroundImgWrapper .devicon {
    position: absolute;
    opacity: 0.05;
    width: 404px;
    left: 50px;
    top: 200px;
}
.BackgroundImgWrapper .designicon {
    position: absolute;
    right: 100px;
    width: 300px;
    opacity: 0.05;
    transform: scaleX(-1);
    top: 20px;
}

.js-work-link {
  position: relative;
}
.js-work-link .underline {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 4px;
  border-radius: 4px;
  background-color: #F7891B;
}







.button {
  margin: 10px auto;
}
.button {
  display: block;
  position: absolute;
  left: 20px;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 9px 6px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button.toggled span:first-child {
  -webkit-transform: translateY(9px) rotate(45deg);
          transform: translateY(9px) rotate(45deg);
}
.button.toggled span:nth-child(2) {
  opacity: 0;
}
.button.toggled span:last-child {
  -webkit-transform: translateY(-9px) rotate(-45deg);
          transform: translateY(-9px) rotate(-45deg);
}
.button span {
  display: block;
  background: rgba(255, 255, 255, 0.9);
  width: 30px;
  height: 4px;
  border-radius: 3px;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.button span:nth-child(2) {
  margin: 5px 0;
}

.flip-box {
  background-color: transparent;
  width: 150px;
  height: 150px;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back {
  transform: rotateY(180deg);
}

.blogCart:hover {
  transform: translateY(-1px);
  box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
}
.blogCart:hover .blogCartMore{
  visibility: visible;
  opacity: 1;
}
.blogCart .blogCartMore {
  transition: visibility 0s, opacity 0.5s linear;
  opacity: 0;
  color: #3182ce;
}

.blogCart {
  transition: all .25s;
}
